.settings__form {
    margin: 20px 0;
    border: 1px solid #e2e5ec;
    padding: 10px 20px 20px 20px;
    border-radius: 7px;
}
.settings__form label {
    margin-bottom:10px;
}
.dark-mode .settings__form label {
    color:white;
}
.settings__form hr {
    border-color: #555860;
    margin: 15px 0;
}
.settings__form button {
    width: fit-content;
    padding: 7px 17px !important;
}
.settings__form .MuiFormControl-root {
    width: 100%;
    margin: 0 0 15px 0;
}
.settings__form__save {
    text-align:right;
}
div#demo-simple-select {
    color:white;
}