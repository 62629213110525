video {
    position: fixed; 
    right: 0; 
    bottom: 0;
    width: 100%; 
    height: 100%; 
    z-index: -100;
    background-size: cover;
    z-index:100;
}
.backarrow svg{
    color:#fff;
    font-size: 30px;
    margin: 20px;
    border:2px solid;  
    padding: 10px;
    border-radius: 40px;
    cursor: pointer;
}
.player__nextvideo {
    position: absolute;
    bottom: 1vh;
    right: 0.25vw;
    background: #1c212e;
    z-index: 100;
    padding: 10px 15px;
    width:75%;
    max-width: 300px;
    border-radius: 4px;
}
.player__nextvideo__title {
    font-weight: bold;
    font-size: 1.25em;   
}
.player__nextvideo__episode {
    font-weight:bold;
    margin:5px 0;
    font-size: 1em;  
}
.player__nextvideo__overview {
    max-height:60px;
    overflow:hidden;
    margin-bottom:15px;
}
@media only screen and (max-width: 767px) {
    .player__nextvideo {
        position: absolute;
        bottom: 1vh;
        right: 0;
        background: #1c212e;
        z-index: 100;
        padding: 10px 15px;
        width:70%;
        max-width: 400px;
        border-radius: 4px;
    }
    .player__nextvideo__title {
        font-weight: bold;
        font-size: 1.25em;   
    }
    .player__nextvideo__episode {
        font-weight:bold;
        margin:5px 0;
        font-size: 1em;  
    }
    .player__nextvideo__overview {
        display:none;
    }
    @me
}