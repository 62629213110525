.headerNavigation {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999999;
    /* background: #00000078; */
    text-align: center;
}
.headerNavigation__search {
    display: block;
    margin-bottom:10px;
    position: relative;
}
.headerNavigation__search::after { 
    content: " ";
    display: block; 
    height: 0; 
    clear: both;
 }
.headerNavigation__search form {
    display: inline-block;
    margin-bottom: 11px;
    margin-right:15px;
    display:none;
}
.headerNavigation__search form button {
    margin-right: -46px;
}
.headerNavigation__search form button svg {
    color:black;
}
.nav__search__mobile {
    display:none;
}
.headerNavigation__search input {
    background: white;
    border: 2px solid #99a4b2;
    color: #99a4b2;
    padding: 13px 50px 13px 37px;
    border-radius: 20px;
    transition: width 0.4s ease-in-out;
}
.nav__search__search__icon {
    font-size: 25px;
    margin-left: 6px;
    vertical-align: middle;
    cursor:pointer;
    color: #fff;
}
.nav__search__settings__icon {
    color: #fff;
    margin-left: 10px;
    vertical-align: middle;
}
::placeholder {
    color:#161616;
    opacity: 1;
}
.headerNavigation__search__avatar {
    position:absolute;
    right:10px;
    top:3px;
}
.headerNavigation__search__form.focused {
    border: 2px solid #24baef;
    border-radius: 4px;    
}
.headerNavigation__search__avatar .MuiAvatar-root {
    float:left;
    text-decoration:none;
}
.headerNavigation__search__avatar__settings {
    display:none;
}
.headerNavigation__categories {
    margin-top: 10px;
    display: inline-block;
}
.headerNavigation__categories a {
    text-decoration:none;  
}
.headerNavigation__categories a .MuiChip-root{
    cursor:pointer;
    border: 1px solid #3e4045;
    font-size:15px;
    height:38px;
}
.headerNavigation__categories a .MuiChip-root span {
    color:white;
}
.headerNavigation__categories__item.focused a .MuiChip-root {
    background-color: #f9ad00 !important;
}
.headerNavigation__categories__item.focused a .MuiChip-root span{
    color: black;
}
.headerNavigation__categories a.active .MuiChip-root{
    background-color:#24baef;
    color:white;
}
@media only screen and (max-width: 820px) {
    .headerNavigation__search__avatar {
        margin-top:2px;
    }
    .headerNavigation__search__avatar__settings {
        display:block;
        float:left;
        margin-left:10px;
    }
    .headerNavigation__search__avatar__settings svg {
        font-size:40px;
        color: #bdbdbd;
    }
    .headerNavigation__search input {
        padding: 13px 5px 13px 37px;   
    }
    .headerNavigation__categories {
        float:left;
        margin-top:5px;
        margin-left:0;
        width: 100%;
    }
    .headerNavigation__search__avatar {
        float:none;
        position:absolute;
        right:10px;
    }
    .headerNavigation__categories a .MuiChip-root {
        height:32px;
        font-size: 0.8125rem;   
    }
    form.headerNavigation__search__form {
        display: none;
    }
    .headerNavigation__search__avatar {
        display:none;
    }
}