.authenicate__container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    background: white;
    color: #131722;
    border-radius: 8px;
    padding: 20px;
    width: 75%;
    max-width: 425px;
}
.authenicate__form {
    border-radius:4px;
}
.authenicate__form label {
    color:#131722;
}
.authenicate__form fieldset {
    border-color: #131722;
}
.MuiFormControl-root {
    width:100%;
}
.authenicate__form button {
    width:100%;
    margin-top:10px;
}
.authenicate__form .MuiTextField-root {
    margin-top:5px;
}
.authenicate__container .MuiAlert-root {
  margin:7px 0 5px 0;
}
html {
    height:100%;
  }
  
  body {
    margin:0;
  }
  
  .bg {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #10171a 50%, #24baef 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
  }
  
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:4s;
  }
  
  .bg3 {
    animation-duration:5s;
  }
  
  @keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }