

@import url('https://fonts.googleapis.com/css?family=Plus+Jakarta+Sans');

.logo {
    color:#20272c;
    display: inline-block;
    line-height: 19px;
    font-size: 2.5em;
}
.dark-mode .logo {
    color:white;
    display: inline-block;
    line-height: 19px;
    font-size: 1.5em;
    margin-left: -5px;
}
.logo-blue {
    color: #24baef !important;
    font-size: 17px;
}
.nav {
    height: 100%;
    display: flex;
    max-width: 70px;
    background:white;
    overflow: hidden;
    transition: 0.3s;
    position: absolute;
    left: 0;
    z-index: 1000;
}
.nav:hover,.nav.expanded {
    max-width: 230px;
    transition: 0.3s;
}
.dark-mode .nav {
    background:#0a0c12;
    border-color:#65656585;
}
.nav__menu__container {
    align-content: space-between;
    align-items: space-between;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px;
}
.nav__menu {
    padding:0;
}
.nav__menu a {
    text-decoration: none;
}
.nav .logo-container {
    display: block;
    width: 100%;
}
.nav__menu li {
    list-style:none;
    display: block;
    margin-right:1.5em;
    margin:25px 0;
    white-space: nowrap;
}
.nav__menu li a{
    color:#9fa9b7;
    font-size:1.1em;
    font-family: Plus Jakarta Sans,serif;
}
.nav__menu li a.active{
    font-weight:bold;
    color:#505965;
}
.dark-mode .nav__menu li a.active{
    color:#ffffff;
}
.nav__menu li a.active::before, .nav__menu li.menu-item.focused a::before {
    content: ' ';
    border-right: 4px solid #24baef;
    position: absolute;
    right: 0px;
    height: 25px;
}

.nav__menu li a svg,
.nav__menu li a .MuiAvatar-root{
    font-size: 1.5em;
    margin-right: 22px;
    vertical-align: top;
}

.nav__menu li a.active svg,  .nav__menu li.focused a svg{
    color:#24baef;
}

span.nav__menu__tile {
    margin-bottom: 20px;
    display: block;
    font-size: 14px;
    font-family: Plus Jakarta Sans,serif;
    width: 0px;
    overflow: hidden;
}
.nav:hover span.nav__menu__tile, .nav.expanded span.nav__menu__tile{
    display: block;
    width:auto;
    transition: 0.3s;
}
.mobileNav {
    display:none;
}
.authenicate .nav__menu,
.authenicate .nav__search,
.authenicate .mobileNav {
    display:none;
}
.authenicate .nav {
    padding: 15px 25px;
}
.nav__menu .MuiChip-outlined {
    margin-left: 10px;
    background-color: #4B3F72 !important;
}
@media only screen and (max-width: 820px) {
    .nav {
        display:none;
    }
    .logo {
        font-size:25px;
    }
    .logo__container {
        display: inline-block;
    }
    .nav__menu__container {
        display: inline-block;
    }
    .nav__menu {
        display: none;
    }
    .nav__search {
        padding-top: 6px;
    }
    .nav__search form {
        margin-right: 0;
    }
    .nav__search svg {
        font-size: 25px;
    }
    .nav__search__settings__icon {
        margin-left:0px;
        margin-top: -4px;
    }
    .mobileNav {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #ebebec;
        z-index: 1000;
        padding-bottom:15px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 10px;
        text-align: center;
    }
    .dark-mode .mobileNav {
        background: #0c0e18;    
    }
    .mobileNav__button {
        text-align:center;
        font-size: 14px;
        font-weight: bold;
    }
    .mobileNav__button button{
        padding: 0 !important;
        text-align: center;
        clear: both;
        display: block;
        width: 100%;
    }
    .mobileNav__button a {
        display: block;
        text-decoration: none;
        padding: 10px 0;
        border-bottom: 3px solid #ebebec;
        color:#626161;
    }
    .dark-mode .mobileNav__button a {
        border-bottom: 3px solid #0c0e18;
        color:#ffffff;
    }
    .mobileNav__button a.active, .mobileNav__button a.active p, .mobileNav__button a.active svg  {
        color: #f9ad00 !important;
    }
    .mobileNav__button a.active .MuiAvatar-circular {
        background-color: #f9ad00 !important;   
        color:#ffffff; 
    }
    .mobileNav__button svg{
        font-size:27px;
        padding: 0;
        margin: 0;
    }
    .mobileNav__button.mobile-search svg {
        margin-bottom:3px !important;
    }
    .mobileNav__button.user .MuiAvatar-circular {
        background: white;
        color: #0c0e18;
        font-size: 17px; 
        margin-bottom:6px !important; 
        width:27px;
        height:27px;
    }
    .mobileNav__button .MuiAvatar-circular {
        margin:0 auto;
        width:30px;
        height:30px;
    }
    .dark-mode .mobileNav__button button svg {
        color:#fff;    
    }
}
@media only screen and (max-width: 280px) {
    .nav {
        padding: 0 5px;    
    }
    .nav__menu {
        margin-left: 9vw;
    }
}