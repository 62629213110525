.search-container form.headerNavigation__search__form {
    display: block !important;
}
.search-container .headerNavigation {
    display: block;
    position: relative;
    margin: 5px 0;   
}
@media only screen and (max-width: 820px) {
    .search-container form.headerNavigation__search__form {
        width:100%;
    }
    .search-container .headerNavigation__search input {
        width:100%;
        font-size: 15px;
        padding: 10px 10px 10px 40px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
    }
}