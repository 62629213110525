.message__manager {
    position: fixed;
    bottom: 0;
    background: #000000a6;
    padding: 20px;
    margin: 20px;
    color: white;
    border: 1px solid #db9800; 
    display:none;
}
.message__manager__title {
    margin-bottom:10px;
    color: white;
}
.message__manager__title button {
    color:white;
}
.MuiCircularProgress-root {
    width:25px !important;
    height:25px !important;
}
.MuiCircularProgress-svg {
    color:white;
}