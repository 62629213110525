@import url('https://fonts.googleapis.com/css?family=Plus+Jakarta+Sans');

* {
  margin: 0;
}
body {
  color: #0a0c12;
}
.App {
  display: flex;
}
.dark-mode {
  background:#0a0c12;
  color:white;
}
.dark-mode::after {
  content: "";
  clear: both;
  display: table;
}
h2, h3, h4, h5 {
  text-transform: capitalize;
}
.label {
  background: rgba(255,255,255,.1);
  padding: 0 7px;
  border-radius: 4px;
  font-size: 0.85em;
}
.row__poster__image .label {
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight:bold;
  padding:5px 15px;
  background: rgb(36 186 239 / 59%);
}
.button {
    padding: 15px 35px;
    margin-right: 10px;
    display: block;
    width: max-content;
    margin-top: 19px;
    text-decoration: none;
    font-weight: bold;
    border:2px solid #20272c;;
    color:#20272c;
    background:none;
    cursor:pointer;
    border-radius:8px;
    font-size: 17px;
    border-radius: 48px;
    justify-content: center;
    padding: 15px 32px;
    font-weight: 700;
    line-height: 18px;
    background:white;
    color:black;
    display: flex;
    align-items: center;
    font-size: 16px;
}
.button svg{
  margin: 0 5px 0 -13px;
  font-size: 25px;
}
.button.main {
  color: #ffffff;
  background-color:#24baef;
  border-color: #24baef;
}
.button.clear {
  background:none;
  border:2px solid #ffffff;
  color:#ffffff;
  padding:15px;
}
.button.clear span.button-text{
  display:none;
}
.button.clear svg, .button.clear img {
  margin:0;
}
.button:hover, .button.secondary:hover, .focused .button{
  color: black;
  background-color: #f9ad00;
  border-color: #f9ad00;
}
.error__bar {
  margin-bottom:10px;
}
.error__bar.hide {
  opacity: 0;
  pointer-events:none;
  display:none;
}
.content-body {
  margin-left: 70px;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  width:100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  position: relative;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.content-body::-webkit-scrollbar {
  display: none;
}
.inner-content-body {
  padding:5px 20px;
}
.MuiAlert-standardError {
  background: rgb(22, 11, 11) !important;
  color: rgb(244, 199, 199) !important;
}
.MuiAlert-standardError .MuiAlert-icon {
  color:rgb(244, 67, 54) !important;
}
@media only screen and (max-width: 820px) {
  .MuiSnackbar-anchorOriginBottomCenter {
    margin-bottom:100px;
  }
  .App {
    height:100%;
  }
  .App::after {
      content: "";
      clear: both;
      display: table;
      height: 50px;
  }
  .content-body {
    margin-bottom:75px;
    margin-left:0;
  }
  .button.clear span.button-text{
    display:block;
  }
  .button.clear svg {
    margin: 0 5px 0 -13px;
  }
  button.button.clear:hover {
    color:white;
    background:none;
    border-color:transparent;
  }
}