.loader-container {
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  background: #000000fa;
  z-index: 200;
}
.loading-modal-container {
  background: #090b11 !important;
  padding: 40px;
  color: white;
  border: none !important;
  outline: none !important;
}
.loader{
  width: 50%;
  min-height:200px;
  min-width:200px;
  background: #0c0e18;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
}
.spinner-container {
  padding:20px;
}
.lds-ring {
  display: block;
  width: 80px;
  height: 80px;
  margin: 10px auto;
}
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: white  transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 820px) {
    .loading-modal-container {
      width:70% !important;
      padding:20px;
    }
  }