fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: rgb(85 88 96);
}
.message p {
    border-radius: 4px;
    padding: 10px;
    margin: 0px 0 15px 0;
    color: #ffffff;
}
.message.error p {
    background: #F44336;
}
.message.sucess p {
    background: #6eab28;
}
textarea#outlined-multiline-static {
    color:white;
}