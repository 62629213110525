.row {
    position: relative;
    margin:20px 0;
}
.row::after {
    content: "";
    clear: both;
    display: table;
}
.row__posters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: 100%;
    gap: 15px;
    margin-top:15px;
}
.row__posters::-webkit-scrollbar {
    display: none;
}
.row__poster {
    transition: transform 450ms;
    position: relative;
    display:inline-block;
    width:100%;
    height:100%;
}
.row__poster img {
    border-radius: 10px;
    width:100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
    border: 2px solid #ffffff00;
}
.banner-item.focused .row__poster, .row__poster:hover {
    /*transform: scale(1.02);*/
}
.row__poster__image img {
    border: 3px solid #ffffff00;
}
.banner-item.focused .row__poster__image img {
    border: 3px solid #f9ad00;
}
.row__poster_info {
    height: 69px;
    padding: 0px 5px;
    width: 100%;
}
.row__poster__image {
    height:100%;
}
.row__poster__fallback {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 40px;
    font-weight:bold;
    text-align: center;
    border-radius: 10px;  
    background: linear-gradient(#222734, #24baef8c);
}
.row__poster__fallback p {
    margin: auto;
}
.row__poster__title {
    position: sticky;
    bottom: 0;
    opacity: 1;
    font-size:20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 32px;
    max-height: 59px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.row__poster_meta {
    font-size:14px;
    color:#cecfd1;
}
.row__poster_meta ul {
    margin: 5px 0 0;
    padding: 0;
    float: left;
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.row__poster_meta ul li {
    list-style: none;
    margin: 0 0px 0px 0;
    padding: 0 10px 0px 0;
    font-size: 14px;
    line-height: normal;
    display: inline;
    align-items: center;
    color: #cecfd1;
    position: relative;
    
}
.row__poster_meta ul li span {
    text-transform:capitalize;
}
.row__poster_meta ul li:last-child {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
}
.row__poster__rating{
    position: absolute;
    left: 10px;
    top: 10px;
}
.row__poster__rating span {
    color: #24baef;
}
.row__poster__rating .MuiTypography-caption {
    color:white;
}
@media only screen and (max-width: 1200px) {
    .row__posters {
        padding:0;
        grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
    }
}
@media only screen and (max-width: 600px) {
    .row__posters {
        grid-template-columns: 48% 48% !important;
    }
    .App>.row__posterLarge:last-of-type {
        margin-bottom:100px;
    }
    .row__poster__title {
        position: relative;
        overflow: visible;
        display:block;  
        max-height: none;  
    }  
}