.modal-container {
    max-width: 90%;
    position: relative !important;
}
.video-container {
    position: relative;
    padding-bottom: 56.25%;
  }
.MuiModal-root iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@media only screen and (min-width: 820px) {
    .modal-container {
        width:80% !important;
    }
}