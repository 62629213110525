.banner {
    overflow-x:hidden;
    padding: 0 25px;
    position: relative;
    min-height: 600px;
    box-shadow: inset 0 0 0 2000px rgba(2,2,2,.7);
    display: flex;
    align-items: center;
    background-size: cover !important;
}
.banner-container {
    position:relative;
}
.banner::after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    background: linear-gradient(0deg,#0a0c12 0%,rgb(10 12 18 / 58%) 15.62%,rgba(0,3,28,0) 31.32%),linear-gradient(180deg,#0a0c12 0%,rgb(10 12 18 / 58%) 7.62%,rgba(0,3,28,0) 31.32%),linear-gradient(90deg,#0a0c12 24.03%,rgba(0,3,28,0) 78.64%);
}
.banner-trailer-container {
    position:absolute;
    width:100%;
    height:100%;
    overflow: hidden;
    left:15%;
}
.banner-trailer-container iframe {
    width: 100%;
    height: 100%;
    transform: scale(1.75);
}
.banner__title {
    text-transform: uppercase;
    font-size: 3em;
    color:#ffffff;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.banner__title .label {
    position: inherit;
    font-size: 46px;
    margin-left: 5px;
    color:#ffffff;
}
.banner ul {
    padding: 0;
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    margin:10px 0;
}
.banner ul li {
    list-style: none;
    line-height: normal;
    display: inline;
    align-items: center;
    color: #ffffff;
    position: relative;
    font-weight: 500;
    font-size: 17px;
    display: flex;
}
.banner ul li:after {
    content: '';
    width: 3px;
    height: 3px;
    background: #FFF;
    opacity: .5;
    border-radius: 100%;
    display: inline-block;
    margin: 0 16px;
    vertical-align: middle;
}
.banner ul li:last-of-type:after {
    display:none;
}
.banner ul li span {
    text-transform:capitalize;

}
.banner ul li:last-child {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
}
.banner ul li .label {
    position:relative;
    top:0;
    right:0;
}
.banner__rating {
    display: inline-grid;
}
.banner__rating span {
    color: #24baef;
}
.banner__rating .MuiTypography-caption {
    color:#ffffff;
}
.banner__description{
    display: block;
    padding-right: 40px;
    font-size: 17px;
    margin: 0 0 10px;
    color:#cccdd2;
    max-width:65%;
}
.banner__contents {
    max-width:50%;
    align-items: center;
    z-index:999;
    position:relative;
}
.banner__contents::after {
    content: "";
    clear: both;
    display: table;
}
.banner-item {
    display:inline-block;
}
.slick-dots {
    position: relative !important;
    bottom:0 !important;
    margin-top:-50px !important;
}
.slick-dots li {
    width:300px !important;
    height:auto !important;
}
.slick-dots.slick-thumb img {
    width:100%;
    border-radius: 10px;
    border:2px solid transparent;
}
.slick-dots.slick-thumb .slick-active img {
    border:2px solid #24baef;
}
@media only screen and (max-width: 1200px) {
    .banner {
        padding: 0 10px;
        margin:5px 0;
    }
    .banner__title {
        font-size:2.75em;
        line-height: 1.15em;
        max-width:100%;
        padding-right:0;
        margin-bottom:5px;
    }
    .banner__description {
        font-size:16px;
        max-width:85%;
    }
    .banner-container {
        padding-bottom:140px;
    }
}
@media only screen and (max-width: 820px) {
    .banner-container {
        padding:10px 0 0 0;
        background-position: top;
    }
    .banner {
        padding: 0 20px 0px 20px;
        margin:50px 0 0 0 !important;
        min-height: 0;
    }
    .banner__contents {
        max-width: 100%;
    }
    .banner__title {
        font-size:2.3em;
        max-width:100%;
        padding-right:0;
    }
    .banner ul {
        margin:5px 0 5px 0;
    }
    .banner ul li {
        font-size:14px;
        padding: 0 5px 0px 0;
    }
    .banner__title .label {
        font-size:30px;
    }
    .banner__description {
        max-width:100%;
        padding-right:0;
        font-size:16px;
        margin-bottom:0;
        max-height:200px;
        overflow: hidden;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 7; /* number of lines to show */
                line-clamp: 7; 
        -webkit-box-orient: vertical;
    }
    .banner_contents__buttons .button {
        padding: 12px 25px;  
        margin-bottom:10px;  
    }
    .banner-trailer-container {
        display: none;
    }
}