.banner-background {
    height:20vh;
    box-shadow: inset 0 0 0 2000px rgba(2, 2, 2, 0.131);
    position: relative;
    background-position: center;
}
.banner-background:after {
    background: linear-gradient(180deg,hsla(0,0%,90%,0) 11%,hsla(0,4%,89%,0) 12%,#0a0c12);
    bottom: 0;
    content: "";
    height: 100px;
    left: 0;
    position: absolute;
    right: 0;
}
.infopane__container::after {
    content: "";
    clear: both;
    display: table;
}
.info-container .button-container {
    display:inline-block;
}
.infopane__banner {
    min-height:350px;
}
.infopane__content{
    margin: 2em auto;
    position: relative;
}
.infopane__content__header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: -100px;
    position:relative;
}
.infopane__content__header::after {
    content: "";
    clear: both;
    display: table;
}

.infopane__content__left {
    width: 100%;
    z-index: 100;
    position: relative;
    padding-right:30px;
    flex: 1;
}
.infopane__further__info {
    background: #181c2b;
    border-radius: 10px;
    padding: 30px 40px;
    min-width: 250px;
}
.infopane__further__info h2 {
    border-bottom: 1px solid #6f6f6f;
    padding-bottom: 10px;
 }
.infopane__further__info .infopane__further__info__content {
    margin:15px 0;
}
.infopane__further__info__content:first-of-type {
    margin-top: 0;
}
.infopane__further__info__content:last-of-type {
    margin-bottom: 0;
}
.infopane__further__info__content .button {
    font-size: 15px;
    padding: 13px 20px;
}   
.infopane__content__right {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
}
.infopane__content__right::before {
    background: linear-gradient(90deg,#ffffff 10%,rgba(15,23,30,0));
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
}
.infopane__content__right:after {
    background: linear-gradient(0deg,#ffffff 0,rgba(15,23,30,0) 50%);
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
}
.dark-mode .infopane__content__right::before {
    background: linear-gradient(90deg,#0f171e 10%,rgba(15,23,30,0));
}
.dark-mode .infopane__content__right:after {
    background: linear-gradient(0deg,#0f171e 0,rgba(15,23,30,0) 50%);
}

.infopane__info {
    position:relative;
}
.infopane__content::after {
    content: "";
    clear: both;
    display: table;
}
.infopane__poster {
    float: left;
    margin-right: 35px;
    width: 25%;
    position: relative;
    max-width:260px;
}
.infopane__poster .button-container {
    position:absolute;
    top:0;
    right:0;
    z-index: 99999;
}
.infopane__poster .button-container {
    width: auto !important;
}
.infopane__poster .button-container .button.clear {
    margin:5px;
    padding:8px;
}
.infopane__poster .button-container .button.clear svg {
    margin: 0;
}
.infopane__poster .button-container .button.clear span.button-text {
    display: none !important;
}
.infopane__poster svg{
    color:white;
    font-size:1.25em;
}
.infopane__poster img{
    width:100%;
    border-radius: 10px;
}
.infopane__title h1 {
    font-size:3em;
}
.infopane__meta__container {
    display: flex;
    align-items: baseline;    
}
.infopane__meta {
    margin: 10px 0;
    padding: 0;
    display: inline-block;
    text-overflow: ellipsis;
}
.infopane__meta li {
    list-style: none;
    margin: 0 0px 0px 0;
    font-size: 18px;
    line-height: normal;
    display: inline;
    align-items: center;
    color: #ffffff;
    position: relative;
    float:left;
    display: flex;
    font-weight: 500;
}
.infopane__meta li span {
    text-transform:capitalize;
}
.banner__rating span {
    padding-left:0;
}
ul.infopane__meta li:last-child {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
}
.infopane__meta li:after {
    display: flex;
    margin: 0 16px;
    content: '';
    width: 3px;
    height: 3px;
    background: #FFF;
    opacity: .5;
}
ul.infopane__meta li:last-child:after {
    display:none;
}
.infopane__genre_seperator:last-of-type {
    display: none;
}
.infopane__description {
    font-size:18px;
    color:#cccdd2;
    margin:0px 0 10px 0;
    max-width: 80%;
}
.infopane__rating {
    display: inline;
    margin-top: -3px;
}
.infopane__rating  span {
    color: #24baef;
}
.infopane__rating  .MuiTypography-caption {
    color:white;
}
.infopane__rating button{
    display: inline-block;
    vertical-align: top;
    padding: 0 !important;
}
.infopane__rating button svg {
    font-size: 35px;
    color: #808b90;
}
.infopane__rating button .secondary-text {
    vertical-align: inherit;
    display: inline-block;
    font-size: 16px;
    padding-top: 9px;
    color: #808b90;
}
.infopane__rating button:hover svg,
.infopane__rating button:hover .secondary-text,
.infopane__rating .added svg,
.infopane__rating .added .secondary-text{
    color: #24baef;
}
.movie-buttons {
    display: inline-block;
}
.movie-buttons .secondary-buttons {
    display:inline-block;
}
.infopane__quality {
    display: inline-block;
    margin-right: 10px;
    color:white;
}
.infopane__episodes_list_epsiode {
    position: relative;
}
.infopane__episodes_list_epsiode .magnet-link{
    position:absolute;
    right:2px;
    top:2px;
}
.infopane__episodes_list_epsiode .infopane__quality {
    width:100%;
}
.infopane__episodes_list_epsiode .infopane__quality .MuiFormControl-root {
    margin: 10px 0;
}
.infopane__quality #quality-select-dropdown {
    padding:5px 40px 5px 0;
    color:white;
    min-width: 90px;
    font-weight:bold;
}
.infopane__quality .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    line-height:1em;
}
.infopane__quality svg {
    color:rgb(255, 255, 255);
}
.infopane__quality label {
    color:white;
}
.infopane__quality fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none; 
}
.infopane__quality__selection {
    margin: 5px 0 0;
    padding: 0;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;   
    margin-top:10px;
}
.infopane__quality__health svg {
    font-size:10px;
    margin-right:5px;
}
.infopane__quality__selection__button {
    padding: 5px 10px;
    margin-right: 10px;
    display:inline-block;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 10px;
}
.movie-buttons button {
    margin:0 10px 0 0;
}
.infopane__quality__selection__button.active {
    border: 1px solid #db9800 !important;
    cursor: pointer;
    color: white;
    border-radius: 10px;
    opacity: 1;
}
.infopane__quality__selection__button:hover {
    border: 1px solid #db9800 !important;
    cursor: pointer;
    color: white;
    border-radius: 10px;
    opacity: 1;
}
.infopane__episodes .MuiTab-textColorPrimary {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 9.5px 17.5px !important;
    border-radius: 5px;
    background: #323846;
    margin: 0 5px;
    color: #24baef;
    min-height: auto !important;
}
.infopane__episodes .MuiTab-textColorPrimary:first-of-type {
    margin-left:0;
}
.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 1 !important;
}
.infopane__episodes .Mui-selected {
    background-color: #24baef;
    color: #1c212e !important;
    font-weight: bold;
}
.infopane__episodes .MuiTab-root.focused {
    background-color: #db9800;
    color: #1c212e !important;
    font-weight: bold;
}
.infopane__episodes .MuiTabs-indicator {
    display:none;    
}
.infopane__episodes_list .MuiBox-root {
    padding:0 !important;
}
.infopane__episodes_list {
    padding: 0;
    display: block;
    margin-top: 10px;
    list-style-type: none;  
    padding: 15px; 
}
.infopane__episodes_list>.MuiBox-root {
    padding: 0px 0 20px 0 !important;
}
.MuiTypography-body1 {
    display: grid;
    gap: 15px;
    grid-template-columns: auto auto auto auto;
}
.infopane__episodes_list_epsiode {
    border:1px solid #10131c;
    padding: 20px;
    border-radius: 10px;
    background: #1e2434;
}
.infopane__episodes_list_epsiode h4{
    margin-bottom:5px;
}
.infopane__episodes_list_epsiode .MuiAccordion-root {
    background-color: #363636d9 !important;
    color:#fff !important;    
}
.infopane__download__buttons {
    grid-gap: 15px;
    display: grid;
    gap: 15px;
    grid-template-columns: auto auto;
}
.infopane__download__buttons .button {
    width:100%;
    padding:12px 0;
    margin: 5px 0 0 0;
}
.infopane__episodes_list_epsiode .button.clear{
    padding:5px;
    margin:10px;
}
.infopane__episodes_list_epsiode .button.clear svg{
    font-size: 17px;
    margin: 0;
}
.infopane__episodes_list_epsiode .button.clear span.button-text {
    display:none !important;
}
@media only screen and (max-width: 820px) {

    .info-container {
        position:relative;
    }
    .infopane__content__header {
        margin-top:0px;
    }
    .infopane__content__left {
        width:100%;
        margin:0;
        padding-right:0;
    }
    .infopane__content__right {
        display:none;
    }
    .infopane__content {
        padding-top:0;
        margin:0 auto;
    }
    .infopane__meta__container {
        align-items: center;   
        display: block;
    }
    ul.infopane__meta.mobile-left {
        width:100%;
        min-width:100%;
        margin:10px 0 10px 0;
        clear:both;
        display: block;
        float:left;
    }
    ul.infopane__meta.mobile-right {
        width:100%;
        min-width:100%;
        margin:0;
        clear:both;
        display: block;
        float:left;
    }
    .infopane__meta li span {
        font-size:15px !important;
        line-height: 15px;
    }
    .infopane__meta li:after {
        margin:0 8px;
    }
    .infopane__rating button svg {
        font-size:35px;
    }
    .infopane__title {
        font-size:1em;
        clear:both;
        line-height:40px;
        margin-bottom:5px;
    }
    .infopane__description {
        font-size:16px;
        max-width:100%;
    }
    .infopane__info {
        width:100%;
        max-width:100%;
    }
    .infopane__rating {
        margin: 7px 0 0 0;
        display: inline;
    }
    .infopane__poster {
        width: 75%;
        float: none;
        margin: 0 auto 20px auto;
        max-width:250px;
        padding-right:0;
    }
    .infopane__poster__overlay {
        font-size: 1rem;
    }    
    .infopane__episodes_list {
        padding:0;
    }
    .infopane__episodes_list>.MuiBox-root {
        padding-top:25px !important;
    }
    .MuiTypography-body1 {
        display:block;
    }
    .infopane__episodes_list_epsiode {
        padding: 7% 5%;
        margin: 0;
        margin-bottom:15px;
    }
    .infopane__episodes__container {
        margin-top:0;
    }
    .movie-buttons {
        margin-bottom: 15px;
        max-width: 100%;
        display: block;
    }
    .movie-buttons .button {
        padding:10px 0;
        width: 100%;
        margin-top:10px;
    }
    .movie-buttons .secondary-buttons {
        grid-gap: 10px;
        display: grid;
        gap: 3%;
        grid-auto-columns: 30%;
        grid-template-areas: "a a a";
        margin:0;
    }
    .movie-buttons .secondary-buttons .button {
        border:none;
        display: block;
        margin:0;
    }
    .movie-buttons .secondary-buttons svg {
        display: block;
        clear: both;
        margin: 10px auto;
    }
    .info-container .button-container {
        width:100%;
    }
    .banner-background {
        margin-bottom:-18vh;
    }
    .infopane__title h1 {
        font-size: 2em;
    }
    .infopane__further__info {
        padding: 15px;
        width:100%;
    }
    .infopane__further__info__content .button {
        padding:13px 0;
        width: 100%;
        margin-top:10px;
        margin-right:5px;
    }
    .infopane__quality .MuiFormControl-root{
        margin:10px 0 5px 0;
    }
    .infopane__download__buttons .button {
        font-size: 14px;
    }
}